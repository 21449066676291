import * as THREE from 'three'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger';
// Register ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

import Experience from './Experience.js'
import WorldLayout from './WorldLayout.js'



// import WorldLayout from './Lanterns.js'


export default class ScrollAnimationMobile
{
  constructor()
  {
      //console.log('Reading World')

      
      this.experience = new Experience()
      this.camera = this.experience.camera
      this.scene = this.experience.scene.scene
      this.resources = this.experience.resources
      this.time = this.experience.time
      
      //this.lights = this.experience.lights

      


      // this.bladesRotation = this.animations.bladesRotationValue


      //Wait for resources
      this.resources.on('ready', ()=>
      {
          //console.log('resources are ready')

          this.worldLayout = new WorldLayout()

          //this.islandGroup = this.worldLayout.islandGroup

        
        //this.triggerAnimationsMobile()
           
      })  

      
        
  }



  triggerAnimationsMobile()
  {
      
    // console.log("this.lights", this.lights)




      //Camera Positions

      var position_01 = [8,5, 15]
      const position_02 = [-7, 5 , 9]
      const position_03 = [8, 1 , 12]
      const position_04 = [7, -10 , 9]

      const camTargetPos_01 = [0, 0.2 , 0]
      const camTargetPos_02 = [0.5, 0.2 , 0] 
      const camTargetPos_03 = [-1, 1.2 , 0] 
      const camTargetPos_04 = [-1, -0.2 , 0] 

    // Create your animation


    const camera = this.camera
    const worldLayout = this.worldLayout

    // Define the animation timeline
    const animationTimelineCamera = gsap.to(this.camera.instance.position, {
        x: position_01[0],
        y: position_01[1],
        z: position_01[2],
        duration: 1, // Duration for the first animation segment
        ease: 'power2.inOut',
      })




    // Define the animation timeline
    const animationTimelineCamTarget = gsap.to(this.camera.controls.target, {
        x: camTargetPos_01[0],
        y: camTargetPos_01[1],
        z: camTargetPos_01[2],
        duration: 1, // Duration for the first animation segment
        ease: 'power2.inOut',
      })


  }



}
