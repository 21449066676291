import Experience from './Experience.js'
import * as THREE from 'three'

// import Animations from './Utils/Animations'

export default class Renderer
{
    constructor()
    {
        this.experience = new Experience()

        this.canvas = this.experience.canvas
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene.scene
        this.camera = this.experience.camera
        
        this.time = this.experience.time

        this.worldLayout = this.experience.worldLayout

        //console.log('W O R L D   L A Y O U T', this.worldLayout)
        this.animations = this.experience.animations
        
        this.variantNight = this.experience.varientNight
        

       this.setInstance()
       this.debug()
    }

setInstance()
    {
        this.instance = new THREE.WebGLRenderer({
            canvas: this.canvas,
            antialias: true  
            })
        
        // console.log('Renderer instance is', this.instance )
        //this.instance.physicallyCorrectLights = true
        this.instance.outputEncoding = THREE.sRGBEncoding
        //this.instance.toneMapping = THREE.CineonToneMapping
        //this.instance.toneMappingExposure = 1.75
        this.instance.shadowMap.enabled = true
        this.instance.shadowMap.type = THREE.PCFSoftShadowMap
  
        this.instance.setSize(this.sizes.width, this.sizes.height)
        this.instance.setPixelRatio(2)
        // this.instance.setPixelRatio(this.sizes.pixelRatio)
        this.instance.setClearColor('#a3daff')
  
        
        
    }

    debug()
    {
        //
    }

    resize()
    {
        this.instance.setSize(this.sizes.width, this.sizes.height)
        this.instance.setPixelRatio(this.sizes.pixelRatio)
        
    }


    update()
    {

        //Update materials
        // this.flagsMotion.flagMaterial.uniforms.uTime.value = this.time.elapsed
        // this.water.waterMaterial.uniforms.uTime.value = this.time.elapsed
        
        //this.animations.bladesRotationValue = this.time.elapsed
        // this.varientNight.sphere.positionX = 2
        
        this.instance.render(this.scene, this.camera.instance)
    
    }

}
