import Experience from './Experience.js'
import * as THREE from 'three'
import { FlatShading } from 'three'



export default class VarientNight
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene.scene
        this.lights = this.experience.lights
        this.resources = this.experience.resources
        this.worldLayout = this.experience.worldLayout
        this.materials = this.experience.materials


        this.ambientValue_nightTime = 0.07
        //This is needed to give the slider a value before it's moved
        // this.slider = document.getElementById("slider")
        // this.sliderValue = 0

        //this.sliderInputs()

    }



    sliderInputs()
    {

        /*
        //Lights Set up
        */
        // var ambientLightValue = 0.3
        // var directionalLightValue = 1

        var ambientLightValue = 0
        var directionalLightValue = 0

        const worldLayout = this.worldLayout

        const ambientLight = this.lights.ambientLight
        const directionalLight = this.lights.directionalLight
        const cabinLight = this.lights.cabinLight
        const windmillLight = this.lights.windmillLight
        
        
        cabinLight.intensity = 0
        windmillLight.intensity = 0 
        







        /*
        //Night Sky Set Up
        */
        const nightSkyOverlay = new THREE.Color ('#2984C0')
        const lerpColor02 = new THREE.Color( '#FBE331')

        const map = this.worldLayout.skyMaterial.map
        const skyMap01 = this.resources.items.skyMap
        const skyMapNight = this.resources.items.skyMapVarientNight

        const skyColor01 = new THREE.Color ('#03111D')

        const skyColorNight = skyColor01.setHSL(0.7, 0.6, 1)        
        worldLayout.skyMaterial.color = skyColorNight


        



        /*
        //Material Set up
        */
        const windows = this.worldLayout.windmillWindows
        const color = ('#03111D')

        const cabinLampGlass = this.worldLayout.cabinLampGlass
        const windmillLampGlass = this.worldLayout.windmillLampGlass
        

        const windowsMaterial =  new THREE.MeshBasicMaterial(
            { color: color
               })


        const lanternsMaterial = this.materials.lanternsMaterial
        
        

        function setMaterialOnVarientObjects()
        {
            
            if (windows)
            {
    
                 windows.traverse((children) =>
                {
                    if(children instanceof THREE.Mesh)
                    {
                        children.material = windowsMaterial
    
                    }
                })
            }

            if (cabinLampGlass)
            {
    
                cabinLampGlass.traverse((children) =>
                {
                    if(children instanceof THREE.Mesh)
                    {
                        //console.log('THIS>CASTLE IS', cabinLampGlass)
                        children.material = lanternsMaterial
    
                    }
                })
            }

            if (windmillLampGlass)
            {
    
                windmillLampGlass.traverse((children) =>
                {
                    if(children instanceof THREE.Mesh)
                    {
                        //console.log('THIS>CASTLE IS', cabinLampGlass)
                        children.material = lanternMaterial
    
                    }
                })
            }




        }

        setMaterialOnVarientObjects()


        this.slider = document.getElementById("slider")
        this.slider.addEventListener("input", getSliderValue)


        function getSliderValue()
        {
            const sliderValue = (this.value-this.min)/(this.max)
            console.log('Slider Value is ', sliderValue)
           
            const lerpColor01 = new THREE.Color ('#03111D')
            const lerpColor02 = new THREE.Color( '#FBE331')
            
            const color = lerpColor01.lerp(lerpColor02, sliderValue)

            const lanternsColor = new THREE.Color ('#03111D')
            const lanternsColorGlow = new THREE.Color ('#FFE674')
            const lanternsLerpColor = lanternsColor.lerp(lanternsColorGlow, sliderValue)


            // ambientIntensity = sliderValue
            // console.log('ambientIntensity', ambientIntensity)
            // this.ambientIntensity = 0
            //this.lights.ambientLight.intensity = ambientIntensity


            function updateWindowsColor()
            {                       
                //console.log(color)
                
                lanternsMaterial.color = lanternsLerpColor

                windowsMaterial.color = color

            }


            function updateLights()
            {
                 

                ambientLight.intensity =  0.3  - (sliderValue /5) 
                directionalLight.intensity = 1 - (sliderValue /1.01)
                cabinLight.intensity = sliderValue * 0.5
                windmillLight.intensity = sliderValue * 0.8 
                
            }


            function updateSkyLerpValue()
            {
                
            const skyColorNight = skyColor01.setHSL(0.7, 0.6,  1-(sliderValue/ 1.5)  )    
                
            }


            updateWindowsColor()
            updateLights()
            updateSkyLerpValue()

            
        }
    }





}