import * as THREE from 'three'

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'

import EventEmitter from "./EventEmitter.js"

export default class Resources extends EventEmitter
{
    constructor(sources)
    {

        super()
        
        //Options
        this.sources = sources

        

        //Set up
        this.items = {}
        this.toLoad = this.sources.length
        //console.log('this.sources.length', this.sources.length)
        this.loaded = 0

        // this.setLoadingBar()
        this.setLoadingManger()
        this.setLoaders()
        this.startLoading()
        

        this.progressRatioSave = []
        //this.sourceLoaded()
        
    }


    setLoadingManger()
    {
        this.loadingManager = new THREE.LoadingManager(
            // Loaded
            () =>
            {
                //console.log('Reading!')
            },
        
        
            // Progress
            (itemUrl, itemsLoaded, itemsTotal) =>
            {
                //console.log('itemUrl is', itemUrl, 'itemsLoaded is', itemsLoaded, 'itemsTotal is', itemsTotal)
                
                this.progressRatio = itemsLoaded / itemsTotal
                //console.log(this.progressRatio)
                
                this.progressRatioSave = this.progressRatio
                
            }
            
        )
    }


    setLoaders()
    {
        
        this.loaders = {}
        this.loaders.gltfLoader = new GLTFLoader(this.loadingManager)
        this.loaders.textureLoader = new THREE.TextureLoader(this.loadingManager)
        this.loaders.cubeTextureLoader = new THREE.CubeTextureLoader(this.loadingManager)

    }

    startLoading()
    {
        //Load each source

        //console.log('this.sources is ',this.sources)

        for(const source of this.sources)
        {
            //console.log('Source is ', source)
            
            if(source.type === 'cubeTexture')
            {
                this.loaders.cubeTextureLoader.load(
                    source.path,
                    (file) =>
                    {
                        //console.log('source, file is' , source, file)
                        this.sourceLoaded(source, file)
                    }
                )
            }

            if(source.type === 'textureMap')
            {
                this.loaders.textureLoader.load(
                    source.path,
                    (file) =>
                    {
                        this.sourceLoaded(source, file)
                    }
                )
            }

            if(source.type === 'gltfModel')
            {
                this.loaders.gltfLoader.load(
                    source.path,
                    (file) =>
                    {   

                        this.sourceLoaded(source, file)
                    }
                )
            }
        }
    }

    sourceLoaded(source, file)
    {

        this.items[source.name] = file

        this.loaded++

        if(this.loaded === this.toLoad)
        {
            //console.log('Finished!')
            this.trigger('ready')
        }
    }


}