import * as THREE from 'three'
import gsap from 'gsap'
import { ScrollTrigger,  } from 'gsap/ScrollTrigger';
// Register ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);


import Experience from './Experience.js'
import WorldLayout from './WorldLayout.js'



// import WorldLayout from './Lanterns.js'


export default class ScrollAnimationDesktop
{
  constructor()
  {
  

      this.experience = new Experience()
      this.camera = this.experience.camera
      this.scene = this.experience.scene.scene
      this.resources = this.experience.resources
      this.time = this.experience.time
      
      //this.lights = this.experience.lights

      


      //Wait for resources
      this.resources.on('ready', ()=>
      {
          //console.log('resources are ready')

          this.worldLayout = new WorldLayout()

          //this.islandGroup = this.worldLayout.islandGroup

        
        // TURN BACK ON TO ENABLE ANIMATION
        //this.triggerAnimationsDesktop()
           
      })  

      
        
  }






  triggerAnimationsDesktop()
  {
      
    // console.log("this.lights", this.lights)



      var position_01 = {x:-3,y:3,z:25}
      const position_02 = [-7, 5 , 9]
      const position_03 = [8, 1 , 12]
      const position_04 = [7, -10 , 9]

      //const camTargetPos_01 = [0,0.8,0]
      const camTargetPos_02 = [0.5, 0.2 , 0] 
      const camTargetPos_03 = [-1, 1.2 , 0] 
      const camTargetPos_04 = [-1, -0.2 , 0] 

    // Create your animation


    const camera = this.camera
    const worldLayout = this.worldLayout



    // window.addEventListener('mouseup', camOnMouseUp);



    // var currentCamPos = []
    // var currentCamControlsPos = []

    // function camOnMouseUp() {
      
    //   //const cameraFunctionStore = camera

    //   console.log("camera current position", camera.instance.position)
    //   //console.log("currentCamPos", currentCamPos)

    //     // if (currentCamPos[0]){
      
        

    //     //console.log("camera camPosStore position", camPosStore)

    //     // //currentCamPos.pop()
    //     // currentCamPos.push(camPosStore)
        
    //     // console.log("camera current position update", camPosStore)
    //     // console.log("currentCamPos update", currentCamPos)

    //     function getCamPosStore() {

    //       var camPosStore = camera.instance.position

    //       let localVar = camPosStore
    //       return localVar
   
    //     }

    //     logCam()
        

    //     // // }

    //     // if (currentCamControlsPos[0]){
      
    //     // var camTargetPosStore = camera.instance.position

    //     // //currentCamControlsPos.pop()
    //     // currentCamControlsPos.push(camTargetPosStore)
        
    //     // console.log("camera current target position update", camTargetPosStore)

    //     // }




        
    //   //   if (currentCamControlsPos[0]){
    //   //     currentCamControlsPos.push(camera.controls.target)

    //   //   console.log("camera controls current position", camera.controls.target)

    //   //   console.log("currentCamPos", currentCamPos)

    //   // }
    // }

    // var getCamVariable = camOnMouseUp.getCamPosStore()


    // function logCam(){
    //   console.log("getCamVariable", getCamVariable)
    // }









    const controlsAnimation = gsap.fromTo(
      camera.controls.target,
      {
        x: camPosStore[0],
        y: camPosStore[1],
        z: camPosStore[2],
        duration: 1,
      },

      {
        x: 2,
        y: 2,
        z: 2,
        duration: 1,
      },
      
      {
        x: camTargetPos_04[0],
        y: camTargetPos_04[1],
        z: camTargetPos_04[2],
        
        duration: 1,
        ease: "power4.out",
        onComplete: function () {
          //console.log("Complete")
        },
      }
    );
  
    // Use ScrollTrigger to trigger the animation when the form comes into view during scrolling
    ScrollTrigger.create({
      trigger: '.section_04',
      start: "top 80%", // Trigger animation when the top of the form is 80% from the top of the viewport
      animation: controlsAnimation, // Use the previously defined fromTo animation
      scrub: true,
      // once: true, // Animation should only occur once
    });





  };





}


