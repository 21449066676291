import Experience from "./Experience"
import * as THREE from 'three'



export default class Grass
{
    
    constructor()
    {
        this.experience = new Experience()
        this.resources = this.experience.resources
        this.scene = this.experience.scene.scene


        this.grassGroup = new THREE.Group()

        //this.findGrassNulls()
        this.makeGrassMaterial()
        this.setGrassGeo()
        
    }


    findGrassNulls()
    {

        this.grassNullsList = this.grassNulls.children[0].children

        var nullsArrayLength = this.grassNullsList.length
        for (var grassNull=0; grassNull<nullsArrayLength; grassNull++) 
        {

            //console.log(grassNull)

        }

    }


    setGrassGeo()
    {
        this.grassGeo = this.resources.items.grassGeo.scene.children[0]
        //this.scene.add(this.grassGeo)


        this.grassGeo.traverse((child) =>
        {
            if(child instanceof THREE.Mesh)
            {

                child.castShadow = true
                child.receiveShadow = true


                child.material = this.grassMaterial
                child.material.side = THREE.FrontSide
                child.material.cull = THREE.BackSide

                
            }
        })

        this.grassGroup.add(this.grassGeo)
    }

    makeGrassMaterial()
    {

        const grassMap = this.resources.items.grassCOL
        
        this.grassMaterial = new THREE.MeshBasicMaterial(
            { map: grassMap, 
               })
        

        this.grassMaterial.map.encoding = THREE.sRGBEncoding
        
        
        

    }

}
