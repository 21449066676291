import Experience from './Experience.js'
import VarientNight from './VarientNight.js'
import Lights from './Lights.js'
// import Clouds from './Clouds.js'
import Grass from './Grass.js'
import Lanterns from './Lanterns.js'
import * as THREE from 'three'
import ChimneySmoke from './Chimney_Smoke.js'


export default class WorldLayout
{
    constructor()
    {
        //console.log('Reading World')

        
        this.experience = new Experience()
        this.scene = this.experience.scene.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.animations = this.experience.animations
        this.materials = this.experience.materials
        
        //Lights are called here so that directional light can be 
        this.lights = new Lights()
        // this.lights.sceneLights()
        
        this.materialTemp = new THREE.MeshStandardMaterial({color: new THREE.Color('red')})

        this.bladesRotation = this.animations.bladesRotationValue



        // //Wait for resources
        // this.resources.on('ready', ()=>
        // {
        //     console.log('resources are ready')

            //Make a group for the scroll trigger to control
            this.islandGroup = new THREE.Group()

            this.makeVarientMaterial()
            this.setIsland()
            this.setTreeMaterials()
            this.setTrees()
            this.setIslandFences()
            this.setWindmill()
            this.setCabin()          
            this.setWindmillWindows()
            this.setWindMillBladesNull()
            this.setWindMillBlades()
            this.windmillBladesSpin()
            //this.setWindmillShadowCatcher()

            this.grass = new Grass()

            this.chimneySmoke = new ChimneySmoke()



            this.setSkySphere()

            //this.clouds = new Clouds()
            this.varientNight = new VarientNight()
            //this.lanterns = new Lanterns()
            
            this.setWindmillLamp()
            this.setCabinLamp()
            //this.lanterns.setLampMaterials()


            
            this.addGrassToIslandGroup()
            //this.addChimneySmokeToGroup()
            this.addIslandGroupToScene()

            

            //this.sceneLights()

        // })  
        
    }

    makeVarientMaterial()
    {
        this.variantMaterial =  new THREE.MeshBasicMaterial(
            { color: new THREE.Color ('#03111D'),
            
            })
    }

    setIsland()
    {
        this.island = this.resources.items.islandModel.scene
        //console.log('THIS>CASTLE IS', this.castle)
        this.islandGroup.add(this.island)


        this.islandMaterial = new THREE.MeshBasicMaterial(
            { map: this.resources.items.islandMap,
                
               })
        //this.islandMaterial.wireframe = true
        this.islandMaterial.map.flipY = false
        this.islandMaterial.map.encoding = THREE.sRGBEncoding

             this.island.traverse((children) =>
            {
                
                if(children instanceof THREE.Mesh)
                {
                    children.material = this.islandMaterial
                    children.material.side = THREE.FrontSide
                    children.material.cull = THREE.BackSide
                }
            })


    }

    setWindmillShadowCatcher()
    {
        this.shadowCatcher = this.resources.items.windmillShadowCatcher.scene.children[0].children[0]
        this.shadowCatcher.scale.set(0.01, 0.01, 0.01)

        const shadowcatcherMaterial =  new THREE.MeshStandardMaterial({ color: new THREE.Color('red'),
                                                                        transparent: true,
                                                                                          })

        this.shadowCatcher.material = shadowcatcherMaterial
        this.shadowCatcher.receiveShadow = true
        this.scene.add(this.shadowCatcher)
    }

    setTreeMaterials()
    {
        //Tree texture A
        this.treeTextureDayA = this.resources.items.treeMap_A
        this.treeMaterialA = new THREE.MeshBasicMaterial()

        this.treeMaterialA.map = this.treeTextureDayA
        this.treeMaterialA.map.flipY = false
        this.treeMaterialA.map.encoding = THREE.sRGBEncoding;
        
        this.treeMaterialA.side = THREE.FrontSide
        this.treeMaterialA.cull = THREE.BackSide


        //Tree texture B
        this.treeTextureDayB = this.resources.items.treeMap_B
        this.treeMaterialB = new THREE.MeshBasicMaterial()

        this.treeMaterialB.map = this.treeTextureDayB
        this.treeMaterialB.map.flipY = false
        this.treeMaterialB.map.encoding = THREE.sRGBEncoding;

        this.treeMaterialB.side = THREE.FrontSide
        this.treeMaterialB.cull = THREE.BackSide

        //Tree texture C
        this.treeTextureDayC = this.resources.items.treeMap_C
        this.treeMaterialC = new THREE.MeshBasicMaterial()

        this.treeMaterialC.map = this.treeTextureDayC
        this.treeMaterialC.map.flipY = false
        this.treeMaterialC.map.encoding = THREE.sRGBEncoding;

        this.treeMaterialC.side = THREE.FrontSide
        this.treeMaterialC.cull = THREE.BackSide

        //Tree texture D
        this.treeTextureDayD = this.resources.items.treeMap_D
        this.treeMaterialD = new THREE.MeshBasicMaterial()

        this.treeMaterialD.map = this.treeTextureDayD
        this.treeMaterialD.map.flipY = false
        this.treeMaterialD.map.encoding = THREE.sRGBEncoding;

        this.treeMaterialD.side = THREE.FrontSide
        this.treeMaterialD.cull = THREE.BackSide

        //Tree texture E
        this.treeTextureDayE = this.resources.items.treeMap_E
        this.treeMaterialE = new THREE.MeshBasicMaterial()

        this.treeMaterialE.map = this.treeTextureDayE
        this.treeMaterialE.map.flipY = false
        this.treeMaterialE.map.encoding = THREE.sRGBEncoding;
        
        this.treeMaterialE.side = THREE.FrontSide
        this.treeMaterialE.cull = THREE.BackSide


    }

    setTrees()
    {

       this.treegroup = this.resources.items.treeModel.scene
        this.scene.add(this.treegroup)
        
        this.treegroup.children.forEach(child => {
            
            if(child.name === "tree_A"){
                child.material = this.treeMaterialA

                child.side = THREE.FrontSide
                child.cull = THREE.BackSide
            }

            if(child.name === "tree_B"){
                child.material = this.treeMaterialB

                child.side = THREE.FrontSide
                child.cull = THREE.BackSide
            }

            if(child.name === "tree_C"){
                child.material = this.treeMaterialC

                child.side = THREE.FrontSide
                child.cull = THREE.BackSide
            }

            if(child.name === "tree_D"){
                child.material = this.treeMaterialD

                child.side = THREE.FrontSide
                child.cull = THREE.BackSide
            }

            if(child.name === "tree_E"){
                child.material = this.treeMaterialE

                child.side = THREE.FrontSide
                child.cull = THREE.BackSide
            }
        })


    }

    






    setIslandFences()
    {
        this.islandFences = this.resources.items.islandFencesModel.scene
        //console.log('THIS>CASTLE IS', this.castle)
        this.islandGroup.add(this.islandFences)

        
        this.islandFencesMaterial = new THREE.MeshBasicMaterial(
            { map: this.resources.items.islandFencesMap,
                
               })
        this.islandFencesMaterial.map.flipY = false
        this.islandFencesMaterial.map.encoding = THREE.sRGBEncoding

 

        this.islandFences.traverse((children) =>
            {
                
                if(children instanceof THREE.Mesh)
                {
                    children.material = this.islandFencesMaterial
                    children.material.side = THREE.FrontSide
                    children.material.cull = THREE.BackSide

                }
            })

    }


    setWindmill()
    {
        this.windmillBase = this.resources.items.windmillBaseModel.scene
        //console.log('THIS>CASTLE IS', this.castle)
        this.islandGroup.add(this.windmillBase)


        const windmillBaseMap_Day = this.resources.items.windmillBaseMap
        const windmillBaseMap_Night = this.resources.items.windmillBaseMap_Night

        //const lerp = new THREE.lerp()

        //Set up Daytime shader
        this.windmillBaseMaterial = new THREE.MeshBasicMaterial(
            { map: windmillBaseMap_Day
               })
        this.windmillBaseMaterial.map.flipY = false
        this.windmillBaseMaterial.map.encoding = THREE.sRGBEncoding

        
        this.windmillBaseMaterial.side = THREE.FrontSide
        this.windmillBaseMaterial.cull = THREE.BackSide




        //console.log("WINDMILL BASE", this.windmillBase)       
        this.windmillBase.children[0].children[0].material = this.windmillBaseMaterial

        










        this.windmillTop = this.resources.items.windmillTopModel.scene
        this.islandGroup.add(this.windmillTop)

        
        this.windmillTopMap = this.resources.items.windmillTopMap


        this.windmillTopMaterial = new THREE.MeshStandardMaterial(
            { 
                //color: new THREE.Color('black')
                map: this.resources.items.windmillTopMap
               })
        this.windmillTopMap.flipY = false
        this.windmillTopMap.encoding = THREE.sRGBEncoding


        const emissiveValue = 0.7
        
        this.windmillTopMaterial.emissiveMap = this.windmillTopMap
        this.windmillTopMaterial.emissive.r = emissiveValue
        this.windmillTopMaterial.emissive.g = emissiveValue
        this.windmillTopMaterial.emissive.b = emissiveValue
        this.windmillTopMaterial.roughness = 1
        // this.windmillTopMaterial.flatShading = true
        





        // this.windmillTopMaterial = new THREE.MeshBasicMaterial(
        //     { 
        //         //color: new THREE.Color('black')
        //         map: this.resources.items.windmillTopMap
        //        })
        // this.windmillTopMap.flipY = false
        // this.windmillTopMap.encoding = THREE.sRGBEncoding








        // this.windmillTopMaterial.flatShading = true

        // console.log("this.windmillTopMaterial", this.windmillTopMaterial)

        // this.windmillTopMaterial = new THREE.MeshStandardMaterial(
        //     { 
        //         //color: new THREE.Color('white'),
        //         map: this.resources.items.windmillTopMap
        //        })
        // this.windmillTopMap.flipY = false
        // this.windmillTopMap.encoding = THREE.sRGBEncoding

        // this.windmillTopMaterial.emissiveMap = this.windmillTopMap

        // const emissiveValue = 0.4

        // this.windmillTopMaterial.emissive.r = emissiveValue
        // this.windmillTopMaterial.emissive.g = emissiveValue
        // this.windmillTopMaterial.emissive.b = emissiveValue
        // this.windmillTopMaterial.roughness = 1
        // // this.windmillTopMaterial.flatShading = true
        


        console.log("this.windmillTopMaterial", this.windmillTopMaterial)


        this.windmillTop.traverse((children) =>
        {
            
            if(children instanceof THREE.Mesh)
            {
               children.material = this.windmillTopMaterial

               children.material.side = THREE.FrontSide
               children.material.cull = THREE.BackSide
       

               children.receiveShadow = true
            }
        })



    }






    setCabin()
    {
        this.cabinBase = this.resources.items.cabinBaseModel.scene
        this.islandGroup.add(this.cabinBase)

  
        
        this.cabinBaseMaterial = new THREE.MeshBasicMaterial(
            { map: this.resources.items.cabinBaseMap,
                
               })
        //this.cabinBaseMaterial.wireframe = true
        this.cabinBaseMaterial.map.flipY = false
        this.cabinBaseMaterial.map.encoding = THREE.sRGBEncoding
            
        this.cabinBaseMaterial.side = THREE.FrontSide
        this.cabinBaseMaterial.cull = THREE.BackSide
        

             this.cabinBase.traverse((children) =>
            {
                
                if(children instanceof THREE.Mesh)
                {
                    children.material = this.cabinBaseMaterial
                }
            })




        this.cabinBody = this.resources.items.cabinBodyModel.scene
        this.islandGroup.add(this.cabinBody)

        
        this.cabinBodyMaterial = new THREE.MeshBasicMaterial(
            // { color: new THREE.Color('#59D01B')
                
            // })
            { map: this.resources.items.cabinBodyMap,
                
               })
        //this.cabinBodyMaterial.wireframe = true
        this.cabinBodyMaterial.map.flipY = false
        this.cabinBodyMaterial.map.encoding = THREE.sRGBEncoding

             this.cabinBody.traverse((children) =>
            {
                
                if(children instanceof THREE.Mesh)
                {
                    children.material = this.cabinBodyMaterial
                    children.material.side = THREE.FrontSide
                    children.material.cull = THREE.BackSide
                }
            })


            this.cabinTiles = this.resources.items.cabinTilesModel.scene
                        this.islandGroup.add(this.cabinTiles)
    
            //Set shadows
            this.cabinTiles.traverse((child) =>
            {
                if(child instanceof THREE.Mesh)
                {
                    //console.log(child)
                    child.castShadow = true
                    child.receiveShadow = true
                }
            })
            
            this.cabinTilesMaterial = new THREE.MeshBasicMaterial(
                // { color: new THREE.Color('#59D01B')
                    
                // })
                { map: this.resources.items.cabinTilesMap,
                    
                   })
            this.cabinTilesMaterial.map.flipY = false
            this.cabinTilesMaterial.map.encoding = THREE.sRGBEncoding
    
                 this.cabinTiles.traverse((children) =>
                {
                    
                    if(children instanceof THREE.Mesh)
                    {
                        children.material = this.cabinTilesMaterial
                        children.material.side = THREE.FrontSide
                        children.material.cull = THREE.BackSide
                    }
                })

    }







 



    setWindmillLamp()
    {

       //Locate lamp animated model
        this.windmillLamp_resource = this.resources.items.windmillLampAnimated


        this.windmillLamp_Model = this.windmillLamp_resource.scene.children[0]
        this.scene.add(this.windmillLamp_Model)




        this.windmillLampMaterial = new THREE.MeshBasicMaterial(
            { map: this.resources.items.windmillBaseMap
               })
        this.windmillBaseMaterial.map.flipY = false
        this.windmillBaseMaterial.map.encoding = THREE.sRGBEncoding



        this.windmillLamp_Model.material = this.windmillLampMaterial
                        
        this.windmillLamp_Model.material.side = THREE.FrontSide
        this.windmillLamp_Model.material.cull = THREE.BackSide
  

        // //Locate windmill glass model
        this.windmillLampGlass = this.resources.items.windmillLampGlassModel.scene.children[0]
        // //Add lamp glass to group
        this.scene.add(this.windmillLampGlass)
        
        
        this.windmillLampGlass.material = this.variantMaterial

        this.windmillLampGlass.material.side = THREE.FrontSide
        this.windmillLampGlass.material.cull = THREE.BackSide

    }









    

    setCabinLamp()
    {
        
        //Locate lamp animated model
        this.cabinLamp_resource = this.resources.items.cabinLampAnimated


        this.cabinLamp_Model = this.cabinLamp_resource.scene.children[0]
        this.cabinLamp_Model.material = this.cabinBodyMaterial

        this.cabinLamp_Model.material.side = THREE.FrontSide
        this.cabinLamp_Model.material.cull = THREE.BackSide


        this.scene.add(this.cabinLamp_Model)

        
    
        this.cabinLampGlass = this.resources.items.cabinLampGlassModel.scene.children[0]
        // //Add lamp glass to group


        this.cabinLampGlass.material = this.variantMaterial

        this.cabinLampGlass.material.side = THREE.FrontSide
        this.cabinLampGlass.material.cull = THREE.BackSide

        this.scene.add(this.cabinLampGlass)

    }








    setWindmillWindows()
    {
        this.windmillWindows = this.resources.items.windmillWindowsModel.scene
        // console.log('THIS>CASTLE IS', this.castle)
        this.islandGroup.add(this.windmillWindows)

        
            
        this.windmillWindows.traverse((child) =>
        {
            if(child instanceof THREE.Mesh)
            {
                //console.log(child)
                child.castShadow = true
                child.receiveShadow = true
                child.material = this.variantMaterial

                child.material.side = THREE.FrontSide
                child.material.cull = THREE.BackSide
            }
        })


        
    }


        













    setWindMillBladesNull()
    {
        //Flag null load
        this.windmillBladesNull = this.resources.items.windMillBladesNull.scene.children[0].children[0]

        this.windmillNull_pos = this.windmillBladesNull.position
        this.windmillNull_rtn = this.windmillBladesNull.rotation

        //console.log("windmillBladesNull", this.windmillBladesNull)

    }


    setWindMillBlades()
    {
        this.bladesGrp = new THREE.Group()

        this.windMillBlades = this.resources.items.windMillBladesModel.scene

        this.windMillBlades.castShadow = true


        this.bladesGrp.add(this.windMillBlades)
        this.islandGroup.add(this.bladesGrp)

        this.bladesGrp.position.x = this.windmillNull_pos.x/100
        this.bladesGrp.position.y = this.windmillNull_pos.y/100
        this.bladesGrp.position.z = this.windmillNull_pos.z/100
        //this.bladesGrp.position.set(this.windmillNull_pos.x - 0.049, this.windmillNull_pos.y, this.windmillNull_pos.z - 0.02)
        
        //console.log("this.windmillNull_pos", this.windmillNull_pos)

        //this.bladesGrp.scale.set(0.8, 0.8, 0.8)

        //this.bladesGrp.rotation.x = this.windmillNull_rtn.z
        this.bladesGrp.rotation.y = this.windmillNull_rtn.y / 2
        this.bladesGrp.rotation.z = this.windmillNull_rtn.z / 2
        //???? Divide import rotations by 2. Not sure if this works yet and if so why?


        this.windMillBlades.traverse((child) =>
        {
            if(child instanceof THREE.Mesh)
            {
                //console.log(child)
                child.castShadow = true
                // child.receiveShadow = true
            }
        })



        const windmillBaseMap_Day = this.windmillBaseMap_Day

        //Set up windmill blades material
        this.windmillBladesMaterial = new THREE.MeshBasicMaterial(
            { map: this.resources.items.windmillTopMap
            })
        // this.windmillBladesMaterial.map.flipY = false
        // this.windmillBladesMaterial.map.encoding = THREE.sRGBEncoding


        this.windmillBladesMaterial.side = THREE.FrontSide
        this.windmillBladesMaterial.cull = THREE.BackSide


        this.windMillBlades.traverse((children) =>
        {
            
            if(children instanceof THREE.Mesh)
            {
                children.material = this.windmillBladesMaterial
            }
        })





    
    }


    windmillBladesSpin()
    {
        this.bladesRotationValue = this.time.elapsed

        this.windMillBlades.rotation.z = - this.bladesRotationValue/3000
        //console.log('TIM EEEELLAAAPPSED', this.animations) 
    }


    setSkySphere()
    {
        /**
         * Sphere
         */

         //const textureLoader = new THREE.TextureLoader()
         //const skyTexture = textureLoader.load('.//static/textures/Sky_02.jpg')
        
        
        // this.set
        
        
        this.skyColorDay_02 =  new THREE.Color ('#FFFFFF')

        this.skyColorDay = new THREE.Color ('#03111D')
        this.skyColorNight = this.skyColorDay.setHSL(0.7, 0.8, 0.3)      


        this.skyTexture = this.resources.items.skyMap


        this.skyMaterial = new THREE.MeshBasicMaterial({
        map: this.skyTexture,
        // color: this.skyColorSwitch.color_01, 
        color: this.skyColorDay_02, 
        
        
        //wireframe: true 
        side: THREE.BackSide
                })


        this.skySphere = new THREE.Mesh(
        new THREE.SphereGeometry(1, 32, 32),
            this.skyMaterial
            )

        this.scene.add(this.skySphere)
        
        this.skySphere.DoubleSide = true
        this.skySphere.scale.set(25,25,25)
        this.skySphere.rotateY = Math.PI * 0.25
        
    }

   

    addGrassToIslandGroup()
    {
        this.islandGroup.add(this.grass.grassGroup)
    }

    addChimneySmokeToGroup()
    {
        this.islandGroup.add(this.chimneySmoke.testSphereMesh)
    }

    addIslandGroupToScene()
    {
        this.scene.add(this.islandGroup)
    }







    update()
    {
        if(this.windMillBlades)
            this.windmillBladesSpin()

        // if (this.clouds)
        //     this.clouds.update()


        
    }


}
