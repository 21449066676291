import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import Experience from "./Experience.js"


export default class Camera
{
    constructor()
    {
        //Set up
        this.experience = new Experience()
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene.scene
        //console.log(this.scene)
        this.canvas = this.experience.canvas
        
        // this.getScreenSize()


        this.setCameraInstanceForMonitor()
        this.setOrbitControlsForMonitor()

        
        //These values are only need for teh
        this.currentCamPos = [5,2, 12]
        this.currentCamCtrlPos = []

    }






    setCameraInstanceForMonitor()
        {

            // Base camera
            this.instance = new THREE.PerspectiveCamera(20, this.sizes.width / this.sizes.height, 0.1, 100)
            this.instance.position.set(5,3, 11)

            this.scene.add(this.instance)

            
        }

    
  


    setOrbitControlsForMonitor()
        {
            this.controls = new OrbitControls(this.instance, this.canvas)
            this.controls.target.set(-1.2, 1 , 0)
            
            const camera = this.instance
            const controls = this.controls

            controls.enableZoom = false
            controls.enableDamping = true
        
            // // Add the wheel event listener to the window (or any other element you want to track scrolling on).
            // window.addEventListener('wheel', handleWheel);
            let isScrolling = false;
            let wheelTimer;
            const holdDuration = 100; // 0.25 seconds in milliseconds
            
            // var camPosVar = []
            
            // if (this.currentCampPos < 0){ 
            //   this.currentCamPos.splice[0]
            //   this.currentCampPos.push(camPosVar[0])
            //   }

            // var camControlsPosVar = []




            // Function to enable the controls.
            //Find camera position here too
            function enableControls() {
                controls.enabled = true;
                controls.update(); // Make sure to update the controls to reflect the change.
                //console.log("Controls enabled!");

                            }
        
              // Function to disable the controls during scroll.
              function disableControls() {
                  controls.enabled = false;
                  controls.update(); // Make sure to update the controls to reflect the change.
                  //console.log("Controls disabled!");
                }
        
              // // Function to simulate a click on the body of the HTML page.
              // function simulateBodyClick() {
              //   const clickEvent = new MouseEvent('click', {
              //     view: window,
              //     bubbles: true,
              //     cancelable: true,
              //   });
              
              //   document.body.dispatchEvent(clickEvent);
              // }
            

        function getCurrentCamPos (){

          var currentCamPos = camera.position

          return currentCamPos
        }


        function handleWheel(event) {
          // Clear any previous timers when the wheel event is triggered.
          clearTimeout(wheelTimer);
          isScrolling = true;
        
          // Disable controls during scroll.
          disableControls();
        
          // Set a timer to detect when the wheel stops turning.
          wheelTimer = setTimeout(() => {
            isScrolling = false;
            //Find current postion of camera
 
            // The wheel has stopped turning, enable controls.
            enableControls();

            var camPos = getCurrentCamPos()
            //console.log("camPos", camPos)

            // Simulate a click on the body of the HTML page after the wheel has stopped turning.
            // simulateBodyClick();
          }, holdDuration);

        }
        
        // Add the wheel event listener to the window (or any other element you want to track scrolling on).
        window.addEventListener('wheel', handleWheel);
        

        
        }


    resize()
        {
            console.log('resize on the camera')
            this.instance.aspect = this.sizes.width / this.sizes.height
            this.instance.updateProjectionMatrix()
        }

    update()
        {
            this.controls.update()
            
        }

}