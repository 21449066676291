import Experience from "./Experience.js"
import * as THREE from 'three'


export default class Materials
{
    constructor()
    {
        this.experience = new Experience()
        this.resources = this.experience.resources

        this.makeLampMaterial()
        // /this.makeCabinBodyMaterial()
    }


    makeLampMaterial()
    {
        this.lanternsMaterial =  new THREE.MeshBasicMaterial(
            {   color: new THREE.Color ('#03111D')
                
               })
    }


    // makeCabinBodyMaterial()
    // {
    //     this.cabinBodyMaterial = new THREE.MeshStandardMaterial(
    //         // { color: new THREE.Color('#59D01B')
                
    //         // })
    //         { map: this.resources.items.cabinBodyMap,
                
    //            })
    //     //this.cabinBodyMaterial.wireframe = true
    //     this.cabinBodyMaterial.map.flipY = false
    //     this.cabinBodyMaterial.map.encoding = THREE.sRGBEncoding
    // }


}