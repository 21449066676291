import './style.css'
import Experience from './Experience/Experience.js'
// import Sizes from './Utilities/Sizes'

import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js'


 const experience = new Experience(document.querySelector('.webgl'))

// const experience = new Experience

// index.js
// function navigateToContact() {
//     window.location.href = 'contact.html';
//   }
  