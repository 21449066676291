import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import Experience from "./Experience.js"


export default class CameraMobile
{
    constructor()
    {
        //Set up
        this.experience = new Experience()
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene.scene
        //console.log(this.scene)
        this.canvas = this.experience.canvas
        this.renderer = this.experience.renderer
        
        // this.getScreenSize()


        this.setCameraInstanceForMobile()
        this.setOrbitControlsForMobile()


        
    }







    setCameraInstanceForMobile()
        {

            // Base camera
            this.instance = new THREE.PerspectiveCamera(20, this.sizes.width / this.sizes.height, 0.1, 100)
            this.instance.position.set(8,5, 15)

            this.scene.add(this.instance)

            
        }

        setOrbitControlsForMobile()
        {
            this.controls = new OrbitControls(this.instance, this.canvas)
            this.controls.target.set(0, 0.2 , 0)
            
            // //Stage 02 pos
            // this.controls.target.set(0.5, 1.2 , 0)
            this.controls.enableZoom = false
            this.controls.enableDamping = true


            
            //  // Disable orbit controls when the mouse button is not pressed
            //  this.controls.enabled = false;

            //  // Add event listeners to enable/disable the controls
            // const controls = this.controls
            
            // window.addEventListener('mousedown', () => {controls.enabled = true;} )

            // window.addEventListener('mouseup', () => {controls.enabled = false;} )


            


            // window.addEventListener('mouseup', onMouseUp, true)

            // // Define the onMouseDown function
            // function onMouseUp(event) {
            //     // Calculate the mouse position in normalized device coordinates
            //     console.log("this.controls", controls)
            //     controls.enabled = false;
            // }





            //  window.domElement.addEventListener('mouseup', () => {
            //     this.controls.enabled = false;
            //  });












        }




    resize()
        {
            //console.log('resize on the camera')
            this.instance.aspect = this.sizes.width / this.sizes.height
            this.instance.updateProjectionMatrix()
        }

    update()
        {
            this.controls.update()
            
        }

}