import gsap, { random } from 'gsap'
import * as THREE from 'three'
import Experience from "./Experience.js"

export default class ChimneySmoke
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene.scene
        this.resources = this.experience.resources

        this.setChimneySmoke()
        //this.update()


    }

    setChimneySmoke()
    {

        const chimneySmokeMaterial = new THREE.MeshBasicMaterial({
            color: new THREE.Color('#808080')})

        chimneySmokeMaterial.side = THREE.FrontSide
        chimneySmokeMaterial.cull = THREE.BackSide


        this.smokeMesh_01 = this.resources.items.chimney_smokePuff_01.scene.children[0]
        this.smokeMesh_01.material = chimneySmokeMaterial



        this.smokePuffScaleStart = 0.001

        this.smokeMesh_01.position.set(0.78, 1.1, -0.6)
        this.smokeMesh_01.scale.set(this.smokePuffScaleStart, this.smokePuffScaleStart, this.smokePuffScaleStart)


        this.smokeMesh_02 = this.smokeMesh_01.clone()
        this.smokeMesh_02.position.set(0.78, 1.1, -0.6)
        this.smokeMesh_02.scale.set(this.smokePuffScaleStart, this.smokePuffScaleStart, this.smokePuffScaleStart)


        this.smokeMesh_03 = this.smokeMesh_01.clone()
        this.smokeMesh_03.position.set(0.78, 1.1, -0.6)
        this.smokeMesh_03.scale.set(this.smokePuffScaleStart, this.smokePuffScaleStart, this.smokePuffScaleStart)


        this.smokeAnimation()
    }

    smokeAnimation()
    {        

        const smokePuffScaleStart = this.smokePuffScaleStart
        const smokePuffScaleEnd = 0.006
        const smokePuffRtnStart = Math.PI/20
        const smokePuffRtnEnd = (Math.random*360)


        this.scene.add(this.smokeMesh_01)
        this.scene.add(this.smokeMesh_02)
        this.scene.add(this.smokeMesh_03)


        const smokePuff_01_posAnim = gsap.timeline({ repeat: -1 });
        smokePuff_01_posAnim.to(this.smokeMesh_01.position, {
            x: 0.78,
            y: 1.5,
            z: -0.6,
            duration: 3,
            ease: 'linear',
        })
        .to(this.smokeMesh_01.position, {
            x: 0.86,
            y: 2.1,
            z: -0.7,
            duration: 5,
            ease: 'linear',
        })
        .repeatDelay(0);

        const smokePuff_01_scaleAnim = gsap.timeline({ repeat: -1 });
        smokePuff_01_scaleAnim.to(this.smokeMesh_01.scale, {
            x: smokePuffScaleEnd/3 + 0.001,
            y: smokePuffScaleEnd/3 + 0.001,
            z: smokePuffScaleEnd/3 + 0.001,
            duration: 1.2,
        })
        .to(this.smokeMesh_01.scale, {
            x:smokePuffScaleEnd + 0.001,
            y:smokePuffScaleEnd + 0.001,
            z:smokePuffScaleEnd + 0.001,
            duration: 6.8,
        })
        .repeatDelay(0);

        const smokePuff_01_rtnAnim = gsap.timeline({ repeat: -1 });
        smokePuff_01_rtnAnim.to(this.smokeMesh_01.rotation, {
            y: 0,
            duration: 1.2,
        })
        .to(this.smokeMesh_01.rotation, {
            y: Math.PI/2,
            duration: 6.8,
        })
        .repeatDelay(0);







        const smokePuff_02_posAnim = gsap.timeline({ repeat: -1 });
        smokePuff_02_posAnim.delay(2) // Add a delay of 2 seconds before the animation starts
        .to(this.smokeMesh_02.position, {
            x: 0.78,
            y: 1.5,
            z: -0.6,
            duration: 3,
            ease: 'linear',
        })
        .to(this.smokeMesh_02.position, {
            x: 0.79,
            y: 2,
            z: -0.7,
            duration: 5,
            ease: 'linear',
        })
        .repeatDelay(0);

        const smokePuff_02_scaleAnim = gsap.timeline({ repeat: -1 });
        smokePuff_02_scaleAnim.delay(2) // Add a delay of 2 seconds before the animation starts
        .to(this.smokeMesh_02.scale, {
            x: smokePuffScaleEnd/3,
            y: smokePuffScaleEnd/3,
            z: smokePuffScaleEnd/3,
            duration: 1.2,
        })
        .to(this.smokeMesh_02.scale, {
            x: smokePuffScaleEnd,
            y: smokePuffScaleEnd,
            z: smokePuffScaleEnd,
            duration: 6.8,
        })
        .repeatDelay(0);

        const smokePuff_02_rtnAnim = gsap.timeline({ repeat: -1 });
        smokePuff_02_rtnAnim.to(this.smokeMesh_01.rotation, {
            y: 0,
            duration: 1.2,
        })
        .to(this.smokeMesh_01.rotation, {
            y: Math.PI/4.5,
            duration: 6.8,
        })
        .repeatDelay(0);








    const smokePuff_03_posAnim = gsap.timeline({ repeat: -1 });
    smokePuff_03_posAnim.delay(3) // Add a delay of 2 seconds before the animation starts
    .to(this.smokeMesh_03.position, {
        x: 0.78,
        y: 1.5,
        z: -0.6,
        duration: 3,
        ease: 'linear',
    })
    .to(this.smokeMesh_03.position, {
        x: 0.84,
        y: 2.1,
        z: -0.5,
        duration: 5,
        ease: 'linear',
    })
    .repeatDelay(0);

    const smokePuff_03_scaleAnim = gsap.timeline({ repeat: -1 });
    smokePuff_03_scaleAnim.delay(3) // Add a delay of 2 seconds before the animation starts
    .to(this.smokeMesh_03.scale, {
        x: smokePuffScaleEnd/3,
        y: smokePuffScaleEnd/3,
        z: smokePuffScaleEnd/3,
        duration: 1.2,
    })
    .to(this.smokeMesh_03.scale, {
        x: smokePuffScaleEnd,
        y: smokePuffScaleEnd,
        z: smokePuffScaleEnd,
        duration: 6.8,
    })
    .repeatDelay(0);

    const smokePuff_03_rtnAnim = gsap.timeline({ repeat: -1 });
    smokePuff_03_rtnAnim.to(this.smokeMesh_01.rotation, {
        y: 0,
        duration: 1.2,
    })
    .to(this.smokeMesh_01.rotation, {
        y: Math.PI/3,
        duration: 6.8,
    })
    .repeatDelay(0);







    }
 





}