import * as THREE from 'three'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger';
// Register ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

import Experience from './Experience.js'
import WorldLayout from './WorldLayout.js'



// import WorldLayout from './Lanterns.js'


export default class ScrollAnimationTablet
{
  constructor()
  {
      //console.log('Reading World')

      
      this.experience = new Experience()
      this.camera = this.experience.camera
      this.scene = this.experience.scene.scene
      this.resources = this.experience.resources
      this.time = this.experience.time
      
      //this.lights = this.experience.lights

          //C A B I N   L I G H T




      // this.bladesRotation = this.animations.bladesRotationValue


      //Wait for resources
      this.resources.on('ready', ()=>
      {
          //console.log('resources are ready')

          this.worldLayout = new WorldLayout()

          //this.islandGroup = this.worldLayout.islandGroup

        
        
        //this.triggerAnimationsTablet()
           
      })  

      
        
  }


  turnOffScrollTrigger() {
    this.cameraAnimationScroll.kill(); // kills the scroll trigger functionality
    this.scrollCameraTarget.kill()
    this.scrollAmbientLight.kill()
    this.scrollCabinLight.kill()
    this.scrollDirectionalLight.kill()
    this.scrollSkyColour.kill()
    this.scrollWindmillLight.kill()
    this.scrollWindowsMaterial.kill()
  }




  triggerAnimationsTablet()
  {
      
    // console.log("this.lights", this.lights)




      //Camera Positions

      const position_01 = [-3,3, 25]
      const position_02 = [-5, 5 , 14]
      const position_03 = [8, 1 , 12]
      const position_04 = [7, -10 , 9]

      //const camTargetPos_01 = [0,0.8,0]
      const camTargetPos_02 = [0.5, 0.2 , 0] 
      const camTargetPos_03 = [-1, 1.2 , 0] 
      const camTargetPos_04 = [-1, -0.2 , 0] 

    // Create your animation


    const camera = this.camera
    const worldLayout = this.worldLayout



    // Define the animation timeline
    this.animationTimelineCamera = gsap.timeline({
      onUpdate: ScrollTrigger.update, // Update ScrollTrigger on every frame of the animation
    });

    this.animationTimelineCamera
      .to(this.camera.instance.position, {
        x: position_02[0],
        y: position_02[1],
        z: position_02[2],
        duration: 1, // Duration for the first animation segment
        ease: 'power2.inOut',
      })
      .to(this.camera.instance.position, {
        x: position_03[0],
        y: position_03[1],
        z: position_03[2],
        duration: 1, // Duration for the second animation segment
        ease: 'power2.inOut',
      })

      .to(this.camera.instance.position, {
        x: position_04[0],
        y: position_04[1],
        z: position_04[2],
        duration: 1, // Duration for the second animation segment
        ease: 'power1.inOut',
      })

    this.cameraAnimationScroll = ScrollTrigger.create({
      trigger: '.wrapper', // Element that triggers the animation
      start: 'top top', // Scroll position to start the animation
      end: 'bottom bottom', // Scroll position to end the animation
      scrub: true,
      animation: this.animationTimelineCamera, // Animation to play
    });





    // Define the animation timeline
    const animationTimelineCamTarget = gsap.timeline({
      onUpdate: ScrollTrigger.update, // Update ScrollTrigger on every frame of the animation
    });

    animationTimelineCamTarget
      .to(this.camera.controls.target, {
        x: camTargetPos_02[0],
        y: camTargetPos_02[1],
        z: camTargetPos_02[2],
        duration: 1, // Duration for the first animation segment
        ease: 'power2.inOut',
      })
      .to(this.camera.controls.target, {
        x: camTargetPos_03[0],
        y: camTargetPos_03[1],
        z: camTargetPos_03[2],
        duration: 1, // Duration for the second animation segment
        ease: 'power2.inOut',
      })

      .to(this.camera.controls.target, {
        x: camTargetPos_04[0],
        y: camTargetPos_04[1],
        z: camTargetPos_04[2],
        duration: 1, // Duration for the second animation segment
        ease: 'power2.inOut',
      })
      
      this.scrollCameraTarget = ScrollTrigger.create({
      trigger: '.wrapper', // Element that triggers the animation
      start: 'top top', // Scroll position to start the animation
      end: 'bottom bottom', // Scroll position to end the animation
      scrub: true,
      animation: animationTimelineCamTarget, // Animation to play
    })






//     const ambientLightNightValue = this.worldLayout.varientNight.ambientValue_nightTime

//     const directionalLight = this.lights.directionalLight;
//     const ambientLight = this.lights.ambientLight;
    
//     // Store the original values of the lights
//     const directionalLightOriginalIntensity = directionalLight.intensity;
//     const ambientLightOriginalIntensity = ambientLight.intensity;
    
//     // Create your animation
//     const directionalLight_nightAnim = gsap.to(directionalLight, {
//       intensity: 0,
//       duration: 3,
//       paused: true // Pause the animation initially
//     });
    
//     // Create your animation
//     const ambientLight_nightAnim = gsap.to(ambientLight, {
//       intensity: 0.1,
//       duration: 3,
//       paused: true // Pause the animation initially
//     });
    
//     this.scrollDirectionalLight = ScrollTrigger.create({
//       trigger: '.anim_trigger_02', // Element that triggers the animation
//       start: 'top -30%', // Start the animation when the top of the trigger element reaches the center of the viewport
//       end: 'bottom center', // End the animation when the bottom of the trigger element reaches the center of the viewport
//       animation: directionalLight_nightAnim, // Animation to play
//       onEnter: () => {
//         // Animation starts, play the animation
//         directionalLight_nightAnim.play();
//       },
//       onLeaveBack: () => {
//         // Animation ends, restore the original intensity
//         gsap.to(directionalLight, { intensity: directionalLightOriginalIntensity, duration: 3 });
//       },
//       toggleActions: 'play none none reverse' // Play the animation when scrolling into the trigger and reverse it when scrolling out
//     });
    
//     this.scrollAmbientLight = ScrollTrigger.create({
//       trigger: '.anim_trigger_02', // Element that triggers the animation
//       start: 'top -30%', // Start the animation when the top of the trigger element reaches the center of the viewport
//       end: 'bottom center', // End the animation when the bottom of the trigger element reaches the center of the viewport
//       animation: ambientLight_nightAnim, // Animation to play
//       onEnter: () => {
//         // Animation starts, play the animation
//         ambientLight_nightAnim.play();
//       },
//       onLeaveBack: () => {
//         // Animation ends, restore the original intensity
//         gsap.to(ambientLight, { intensity: ambientLightOriginalIntensity, duration: 3 });
//       },
//       toggleActions: 'play none none reverse' // Play the animation when scrolling into the trigger and reverse it when scrolling out
//     });
    







// //S K Y 

  const skyColorDay = this.worldLayout.skyColorDay_02
  const skyColorNight = this.worldLayout.skyColorNight

  console.log("skyColorDay before anim", skyColorNight)


 // Create your animation
    const nightSkySwitch_Anim = gsap.to(this.worldLayout.skyMaterial.color, {
      r: skyColorNight.r,
      g: skyColorNight.g,
      b: skyColorNight.b,

      duration: 2,
      paused: true // Pause the animation initially
    });

    this.scrollSkyColour = ScrollTrigger.create({
      trigger: '.anim_trigger_02', // Element that triggers the animation
      start: 'top -30%', // Start the animation when the top of the trigger element reaches the center of the viewport
      end: 'bottom center', // End the animation when the bottom of the trigger element reaches the center of the viewport
      animation: nightSkySwitch_Anim, // Animation to play
      onEnter: () => {
        // Animation starts, play the animation
        nightSkySwitch_Anim.play();
      },
      onLeaveBack: () => {
        // Animation ends, restore the original intensity
        gsap.to(this.worldLayout.skyMaterial.color, { r: skyColorDay.r,
                                                      g: skyColorDay.g,
                                                      b: skyColorDay.b,
                                                       duration: 3 });
      },
      toggleActions: 'play none none reverse',
      delay: 1 // Play the animation when scrolling into the trigger and reverse it when scrolling out
    });

// 


//     //C A B I N   L I G H T

//     //Turn off shadows
//     console.log("this.lights.cabinLight", this.lights.cabinLight)

// //  // Create your animation
// //     const cabinLight_Anim = gsap.to(this.lights.cabinLight, {
// //     intensity:0.5,

// //       duration: 3,
// //       paused: true // Pause the animation initially
// //     });


// //     this.scrollCabinLight = ScrollTrigger.create({
// //       trigger: '.anim_trigger_02', // Element that triggers the animation
// //       start: 'top -30%', // Start the animation when the top of the trigger element reaches the center of the viewport
// //       end: 'bottom center', // End the animation when the bottom of the trigger element reaches the center of the viewport
// //       animation: cabinLight_Anim, // Animation to play
// //       onEnter: () => {
// //         // Animation starts, play the animation
// //         cabinLight_Anim.play();
// //       },
// //       onLeaveBack: () => {
// //         // Animation ends, restore the original intensity
// //         gsap.to(this.lights.cabinLight, {intensity:0,  duration: 3})
// //       },
// //       toggleActions: 'play none none reverse' // Play the animation when scrolling into the trigger and reverse it when scrolling out
// //     });




// //     //W I N D M I L L    L I G H T

// //  // Create your animation
// //     const windmillLight_Anim = gsap.to(this.lights.windmillLight, {
// //     intensity:0.8,

// //       duration: 3,
// //       paused: true // Pause the animation initially
// //     });


// //     this.scrollWindmillLight = ScrollTrigger.create({
// //       trigger: '.anim_trigger_02', // Element that triggers the animation
// //       start: 'top -30%', // Start the animation when the top of the trigger element reaches the center of the viewport
// //       end: 'bottom center', // End the animation when the bottom of the trigger element reaches the center of the viewport
// //       animation: windmillLight_Anim, // Animation to play
// //       onEnter: () => {
// //         // Animation starts, play the animation
// //         windmillLight_Anim.play();
// //       },
// //       onLeaveBack: () => {
// //         // Animation ends, restore the original intensity
// //         gsap.to(this.lights.windmillLight, {intensity:0,  duration: 3})
// //       },
// //       toggleActions: 'play none none reverse' // Play the animation when scrolling into the trigger and reverse it when scrolling out
// //     });






// // W I N D O W S


// const windowOriginalColor = this.worldLayout.variantMaterial.color

// const lanternsColorGlow = new THREE.Color ('#FFE674')

//  // Create your animation
//  const windowsMaterial_Anim = gsap.to(this.worldLayout.variantMaterial.color, {
//   r: lanternsColorGlow.r,
//   g: lanternsColorGlow.g,
//   b: lanternsColorGlow.b,

//     duration: 4,
//     paused: true // Pause the animation initially
//   });



//   this.scrollWindowsMaterial = ScrollTrigger.create({
//     trigger: '.anim_trigger_02', // Element that triggers the animation
//     start: 'top -30%', // Start the animation when the top of the trigger element reaches the center of the viewport
//     end: 'bottom center', // End the animation when the bottom of the trigger element reaches the center of the viewport
//     animation: windowsMaterial_Anim, // Animation to play
//     onEnter: () => {
//       // Animation starts, play the animation
//       windowsMaterial_Anim.play();
//     },
//     onLeaveBack: () => {
//       // Animation ends, restore the original intensity
//       gsap.to(this.worldLayout.variantMaterial.color, {   r: windowOriginalColor.r,
//                                                           g: windowOriginalColor.g,
//                                                           b: windowOriginalColor.b,  
//                                                           duration: 0.1})
//     },
//     toggleActions: 'play none none reverse' // Play the animation when scrolling into the trigger and reverse it when scrolling out
//   });






  }



  triggerAnimationsMobile()
  {
      
    // console.log("this.lights", this.lights)




      //Camera Positions

      const position_01 = [8,5, 15]
      const position_02 = [-7, 5 , 9]
      const position_03 = [8, 1 , 12]
      const position_04 = [7, -10 , 9]

      const camTargetPos_01 = [0, 0.2 , 0]
      const camTargetPos_02 = [0.5, 0.2 , 0] 
      const camTargetPos_03 = [-1, 1.2 , 0] 
      const camTargetPos_04 = [-1, -0.2 , 0] 

    // Create your animation


    const camera = this.camera
    const worldLayout = this.worldLayout

    // Define the animation timeline
    const animationTimelineCamera = gsap.to(this.camera.instance.position, {
        x: position_01[0],
        y: position_01[1],
        z: position_01[2],
        duration: 1, // Duration for the first animation segment
        ease: 'power2.inOut',
      })




    // Define the animation timeline
    const animationTimelineCamTarget = gsap.to(this.camera.controls.target, {
        x: camTargetPos_01[0],
        y: camTargetPos_01[1],
        z: camTargetPos_01[2],
        duration: 1, // Duration for the first animation segment
        ease: 'power2.inOut',
      })


  }




  triggerAnimationsMobileLandscape()
  {
      
    // console.log("this.lights", this.lights)




      //Camera Positions

      const position_01 = [5,3, 10]
      const position_02 = [-7, 5 , 9]
      const position_03 = [8, 1 , 12]
      const position_04 = [7, -10 , 9]

      const camTargetPos_01 = [-2, 0.5 , 0]
      const camTargetPos_02 = [0.5, 0.2 , 0] 
      const camTargetPos_03 = [-1, 1.2 , 0] 
      const camTargetPos_04 = [-1, -0.2 , 0] 

    // Create your animation


    const camera = this.camera
    const worldLayout = this.worldLayout

    // Define the animation timeline
    const animationTimelineCamera = gsap.to(this.camera.instance.position, {
        x: position_01[0],
        y: position_01[1],
        z: position_01[2],
        duration: 1, // Duration for the first animation segment
        ease: 'power2.inOut',
      })




    // Define the animation timeline
    const animationTimelineCamTarget = gsap.to(this.camera.controls.target, {
        x: camTargetPos_01[0],
        y: camTargetPos_01[1],
        z: camTargetPos_01[2],
        duration: 1, // Duration for the first animation segment
        ease: 'power2.inOut',
      })


  }













}
