import Experience from "./Experience.js"
import * as THREE from 'three'
import {gsap} from 'gsap'



export default class LoadingAnimation
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene.scene
        this.camera = this.experience.camera.instance
        this.resources = this.experience.resources
        // this.progressRatio = this.resources.progressRatioSave
        // console.log('this.resources is UWRYEWU', this.progressRatio)
        // this.time = this.experience.time

    
        this.sphereStartScale = 0.01
        this.sphereEndScale = 0.6
        
        // // this.animationOverlay()
        // this.makeGeoOverlay()
        // this.setGeoOverlay()
        this.sphereScale = 0.01



    
    }


    makeSphereGeoOverlay()
    {
        this.sphere = new THREE.Mesh(
            new THREE.SphereGeometry(1, 32, 32),
            new THREE.MeshStandardMaterial({
                color: 'black'})
        )

        this.scene.add(this.sphere)
        //this.sphere.position.z = -3

        //console.log("loading sphere", this.sphere)
    }

    setSphereGeoOverlay()
    {
        this.camera.add(this.sphere)
        this.sphere.position.set(0, 0, -4 )

    
    }

    animateSphereGeoOverlay()
    {
        
        this.loadingProgress = this.resources.progressRatio
        this.sphereScale = this.sphereEndScale * this.loadingProgress
        
        
        this.sphere.scale.set(this.sphereScale, this.sphereScale, this.sphereScale)
        // console.log(this.loadingProgress)
        //Get progressRatio from resources

        //Kill command after fully loaded
    }

    update()
    {   
        this.animateSphereGeoOverlay()
        console.log(this.loadingProgress)
    }



}