import Experience from './Experience.js'
import * as THREE from 'three'
import {gsap} from 'gsap'
import LoadingAnimation from './LoadingAnimation.js'

export default class LoadingScreen
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene.scene
        this.resources = this.experience.resources
        this.loadingAnimation = new LoadingAnimation()

        this.loadingBarElement = document.querySelector('.loading-bar')
        
        //this.loadingPercentNumber = document.getElementsByClassName('loading-percent-number')
        this.loadingPercentNumber = document.getElementById('loading-percent-number')
        this.domSection_01 = document.querySelectorAll ('.left-col')
        this.domSection_04 = document.getElementsByClassName ('section_04')
        

        console.log(this.loadingPercentNumber)
        console.log(this.domSection_04)
        //console.log('this.loadingAnimation', this.loadingAnimation)
        
        

        //Wait for resources
        this.resources.on('ready', ()=>
        {
            //console.log('resources for Loading Screen are ready')

            //this.loadingBarFadeOff()
            this.hidePercentageNumber()
            this.loadingAnimationHide()
            this.section_01FadeOn()

            this.overlayFade()
            //this.loadingBarFadeOff()

            
        })


        this.overlay()
        this.loadingAnimOverlay()
        
    }



    overlay()
    {
    /**
     * Overlay
     */
        this.overlayGeometry = new THREE.PlaneBufferGeometry(2,2,1,1)
        this.overlayMaterial = new THREE.ShaderMaterial({
            transparent:true,
            uniforms:
            {
                uAlpha: { value: 1 }
            },
            vertexShader: `
                void main()
                {
                    gl_Position = vec4(position, 1.0);
                }
            `,
            fragmentShader: `
                uniform float uAlpha;

                void main()
                {
                    gl_FragColor = vec4(0.3, 0.7, 0.9, uAlpha);
                }
            `

            })


        const overlay = new THREE.Mesh(this.overlayGeometry, this.overlayMaterial)
        this.scene.add(overlay) 
    }


    overlayFade()
    {
        gsap.to(this.overlayMaterial.uniforms.uAlpha, { delay:1, duration: 5, value: 0 })

    }


    loadingAnimOverlay()
    {

        this.loadingAnimation.makeSphereGeoOverlay()
        this.loadingAnimation.setSphereGeoOverlay()
    }

    loadingAnimationHide()
    {
        this.loadingAnimation.sphere.visible = false
    }

    animateLoadingBar()
    {
        // this.loadingProgress = this.resources.progressRatio
        // this.loadingBarElement.style.transform = `scaleX(${this.loadingProgress})`


        //console.log(this.loadingPercentNumber)
        var loadingRatioPercent = (this.resources.progressRatio * 100 ) 
        this.loadingPercentNumber.innerText = loadingRatioPercent.toFixed(0)  + '%'
    }

    hidePercentageNumber()
    {
        this.loadingPercentNumber.style.opacity = 0
    }



    section_01FadeOn()
    {
        //this.domSection_01.style.opacity = 0;

        // Animate opacity with GSAP
        gsap.to(this.domSection_01, { duration: 2, opacity: 1 });
        gsap.to(this.domSection_04, { duration: 2, opacity: 1 });
    }


    loadingBarFadeOff()
    {
        //console.log("this.loadingBarElement", this.loadingBarElement.style.transform)
        //this.loadingBarElement.style = `opacity(${0})`
        // gsap.to(this.loadingBarElement.style.opacity, { duration: 1, value: 0 })


        this.loadingBarElement.style.opacity = 100;

        // Animate opacity with GSAP
        gsap.to(this.loadingBarElement, { duration: 1, opacity: 0 });
    }

    update()
    {   
        this.animateLoadingBar()
        //console.log(this.loadingProgress)
    }

}