import Experience from "./Experience.js"

export default class Animations
{
    constructor()
    {
        this.experience = new Experience()
        this.time = this.experience.time
        
        
        this.bladesRotationValue = 0
        
    }



    setMovingItemsWhenLoaded() 
    {
        
        this.worldLayout = this.experience.worldLayout
        this.windMillBlades = this.worldLayout.windMillBlades
    }


    windmillBladesSpin()
    {
        this.bladesRotationValue = this.time.elapsed
        
    }

    update()
    {
        
        this.bladesRotationValue = this.time.elapsed

    }




}