import * as THREE from 'three'


export default class Scene
{
    constructor()
    {


        // //set up
        // this.Experience = new Experience()

        // this.canvas = this.experience.canvas()

        this.scene = new THREE.Scene()
        this.setBackgroundColour()
        this.sceneSettings()

    }

setBackgroundColour()
    {
        this.backgroundColour = '#a3daff'
        //this.backgroundColour = '#E587D9'
    }    

sceneSettings()
    {
        
        const fog = new THREE.Fog(this.backgroundColour, 20, 30)
        //this.scene.fog = fog
        
    }



}