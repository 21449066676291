import Experience from "./Experience.js"
import * as THREE from 'three'
//import VarientNight from "./VarientNight"

export default class Lights 
{
    constructor()
    {

        //Set up
        this.experience = new Experience()
        this.scene = this.experience.scene.scene
        this.varientNight = this.experience.varientNight
        this.worldLayout = this.experience.worldLayout

        this.ambientLightValue = 0.3
        this.directionalLightValue = 0.5

        this.sceneLights()
        //this.setTempSphere()

    }

    sceneLights()
        {
            /**
             * Lights
             */

            
            // this.ambientLight = new THREE.AmbientLight(0xffffff, this.ambientLightValue)
            // this.scene.add(this.ambientLight)
    
            this.directionalLight = new THREE.DirectionalLight(0xffffff, this.directionalLightValue)
            this.directionalLight.castShadow = true
            this.directionalLight.shadow.mapSize.set(1024, 1024)
            this.directionalLight.shadow.normalBias = 0
            this.directionalLight.shadow.camera.far = 13
            this.directionalLight.shadow.camera.left = - 2
            this.directionalLight.shadow.camera.top = 2
            this.directionalLight.shadow.camera.right = 2
            this.directionalLight.shadow.camera.bottom = - 2
            this.directionalLight.position.set(4, 5, 5)
            this.scene.add(this.directionalLight)

            // if (this.worldLayout.cabinLampNull)
            //     {
                    
                    
            //     }
            // this.cabinLight()
            // this.windmillLight()
            //this.setTempSphere()
        }


        cabinLight()
        {
            this.cabinLight = new THREE.PointLight('#FFD97B', 0)
            this.cabinLight.castShadow = true
            this.cabinLight.shadow.mapSize.set(256, 256)
            this.cabinLight.shadow.normalBias = 0.1

            
            //this.cabinLight.position.set(0.2, 0.6, -0.02)

            this.cabinLight.position.set(0.21931485831737518, 0.567, -0.0751841589808464)
            this.scene.add(this.cabinLight)
        }

        windmillLight()
        {
            this.windmillLight = new THREE.PointLight('#FFD97B', 0)
            this.windmillLight.castShadow = true
            this.windmillLight.shadow.mapSize.set(256, 256)
            this.windmillLight.shadow.normalBias = 0.1

            
            //this.cabinLight.position.set(0.2, 0.6, -0.02)
            this.windmillLight.position.set(-0.9703840017318726, 0.58, 0.11648000031709671)
            this.scene.add(this.windmillLight)
        }


        setTempSphere()
        {
            /**
             * Sphere
             */
            
            this.sphere = new THREE.Mesh(
                new THREE.SphereGeometry(0.05, 32, 32),
                this.sphereMaterial)
                 
            //console.log('this.worldLayout.cabinLampNull.position', this.worldLayout.cabinLampNull.position)
            
            
            //Numbers copied from cabin lamp null. (Y value adusted manually)
            this.sphere.position.set(0.21931485831737518, 0.567, -0.0751841589808464)
            this.scene.add(this.sphere)
        }





}