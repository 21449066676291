import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import Experience from "./Experience.js"


export default class CameraTablet
{
    constructor()
    {
        //Set up
        this.experience = new Experience()
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene.scene
        console.log(this.scene)
        this.canvas = this.experience.canvas
        
        // this.getScreenSize()


        this.setCameraInstanceForTablet()
        this.setOrbitControlsForTablet()


        
    }







    setCameraInstanceForTablet()
        {

            // Base camera
            this.instance = new THREE.PerspectiveCamera(20, this.sizes.width / this.sizes.height, 0.1, 100)
            this.instance.position.set(8,5, 12)

            this.scene.add(this.instance)

            
        }

        setOrbitControlsForTablet()
        {
            this.controls = new OrbitControls(this.instance, this.canvas)
            this.controls.target.set(-1.2, 0.2 , 0)
            
            // //Stage 02 pos
            // this.controls.target.set(0.5, 1.2 , 0)
            this.controls.enableDamping = true
            this.controls.enableZoom = false

        }




    resize()
        {
            console.log('resize on the camera')
            this.instance.aspect = this.sizes.width / this.sizes.height
            this.instance.updateProjectionMatrix()
        }

    update()
        {
            this.controls.update()
            
        }

}