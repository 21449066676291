export default [

    // {
    //     name: 'environmentMapTexture',
    //     type: 'cubeTexture',
    //     path: 
    //     [
    //         'textures/environmentMap/px.jpg',
    //         'textures/environmentMap/nx.jpg',
    //         'textures/environmentMap/py.jpg',
    //         'textures/environmentMap/ny.jpg',
    //         'textures/environmentMap/px.jpg',
    //         'textures/environmentMap/nz.jpg'
            
    //     ]
    // },
  


        /**
     * Models
     */
    // {
    //     name: 'castleModel',
    //     type: 'gltfModel',
    //     path: 
    //     [
    //         '../static/Windmill_Island/Windmill_Island_noWindows_02.gltf'
            
    //     ]
    // },

    {
        name: 'islandModel',
        type: 'gltfModel',
        path: 
        [
            '../Windmill_Island/Island.gltf'
            
        ]
    },

    


    {
        name: 'treeModel',
        type: 'gltfModel',
        path: 
        [
            '../Windmill_Island/trees_forBakedMaps_noCompression.gltf'
            
        ]
    },
    
    // {
    //     name: 'treesNulls',
    //     type: 'gltfModel',
    //     path: 
    //     [
    //         '../Windmill_Island/Tree_nulls.gltf'
            
    //     ]
    // },








    // {
    //     name: 'clouds',
    //     type: 'gltfModel',
    //     path: 
    //     [
    //         '../Windmill_Island/Clouds.gltf'
            
    //     ]
    // },




    {
        name: 'chimney_smokePuff_01',
        type: 'gltfModel',
        path: 
        [
            '../Windmill_Island/Chimney_smokePuff_01.gltf'
            
        ]
    },











    {
        name: 'islandFencesModel',
        type: 'gltfModel',
        path: 
        [
            '../Windmill_Island/Island_Fences.gltf'
            
        ]
    },

    {
        name: 'windmillBaseModel',
        type: 'gltfModel',
        path: 
        [
            '../Windmill_Island/Windmill_Base.gltf'
            
        ]
    },

    {
        name: 'windmillTopModel',
        type: 'gltfModel',
        path: 
        [
            '../Windmill_Island/Windmill_Top.gltf'
            
        ]
    },

    {
        name: 'windmillLampModel',
        type: 'gltfModel',
        path: 
        [
            '../Windmill_Island/Windmill_Lamp.gltf'
            
        ]
    },

    {
        name: 'windmillLampGlassModel',
        type: 'gltfModel',
        path: 
        [
            '../Windmill_Island/Windmill_Lamp_Glass.gltf'
            
        ]
    },

    {
        name: 'windmillLampNull',
        type: 'gltfModel',
        path: 
        [
            '../Windmill_Island/Windmill_Lamp_null.gltf'
            
        ]
    },

    {
        name: 'windmillLampAnimated',
        type: 'gltfModel',
        path: 
        [
            '../Windmill_Island/Windmill_Lamp_Animated.gltf'
            
        ]
    },


    // {
    //     name: 'windmillShadowCatcher',
    //     type: 'gltfModel',
    //     path: 
    //     [
    //         '../Windmill_Island/Windmill_ShadowCatcher.gltf'
            
    //     ]
    // },







    {
        name: 'cabinBaseModel',
        type: 'gltfModel',
        path: 
        [
            '../Windmill_Island/Cabin_Base.gltf'
            
        ]
    },

    {
        name: 'cabinBodyModel',
        type: 'gltfModel',
        path: 
        [
            '../Windmill_Island/Cabin_Body.gltf'
            
        ]
    },
    
    {
        name: 'cabinTilesModel',
        type: 'gltfModel',
        path: 
        [
            '../Windmill_Island/Cabin_Tiles_02.gltf'
            
        ]
    },

    {
        name: 'cabinLampModel',
        type: 'gltfModel',
        path: 
        [
            '../Windmill_Island/Cabin_Lamp.gltf'
            
        ]
    },

    {
        name: 'cabinLampGlassModel',
        type: 'gltfModel',
        path: 
        [
            '../Windmill_Island/Cabin_Lamp_Glass.gltf'
            
        ]
    },

    {
        name: 'cabinLampNull',
        type: 'gltfModel',
        path: 
        [
            '../Windmill_Island/Cabin_Lamp_null.gltf'
            
        ]
    },

    {
        name: 'cabinLampAnimated',
        type: 'gltfModel',
        path: 
        [
            '../Windmill_Island/Cabin_Lamp_Animated.gltf'
            
        ]
    },









    {
        name: 'windmillWindowsModel',
        type: 'gltfModel',
        path: 
        [
            '../Windmill_Island/Windmill_Island_windows_03.gltf'
            
        ]
    },

    {
        name: 'windMillBladesModel',
        type: 'gltfModel',
        path: 
        [
            '../Windmill_Island/Windmill_Blades.gltf'
            
        ]
    },
    

    {
        name: 'windMillBladesNull',
        type: 'gltfModel',
        path: 
        [
            '../Windmill_Island/Windmill_Blades_null.gltf'
            
        ]
    },



    // {
    //     name: 'grassNulls',
    //     type: 'gltfModel',
    //     path: 
    //     [
    //         '../Windmill_Island/grassNulls.gltf'
            
    //     ]
    // },



    {
        name: 'grassGeo',
        type: 'gltfModel',
        path: 
        [
            '../Windmill_Island/grassGeo.gltf'
            
        ]
    },




    
    /**
     * Textures
     */

     {
        name: 'islandMap',
        type: 'textureMap',
        path: 
        [
            // '../Windmill_Island/baked-island_lowShape.jpg'
            '../texturesBakedDay/baked-Island_lowShape.jpg'
            
        ]
    },

    {
        name: 'treeMap_A',
        type: 'textureMap',
        path: 
        [
            '../texturesBakedDay/tree_A_bake.jpg'
            
        ]
    },

    {
        name: 'treeMap_B',
        type: 'textureMap',
        path: 
        [
            '../texturesBakedDay/tree_B_bake.jpg'
            
        ]
    },

    {
        name: 'treeMap_C',
        type: 'textureMap',
        path: 
        [
            '../texturesBakedDay/tree_C_bake.jpg'
            
        ]
    },

    {
        name: 'treeMap_D',
        type: 'textureMap',
        path: 
        [
            '../texturesBakedDay/tree_D_bake.jpg'
            
        ]
    },

    {
        name: 'treeMap_E',
        type: 'textureMap',
        path: 
        [
            '../texturesBakedDay/tree_E_bake.jpg'
            
        ]
    },

     {
        name: 'islandFencesMap',
        type: 'textureMap',
        path: 
        [
            '../Windmill_Island/Windmill_Island_forSubstance_Fences_BaseColor.jpg'
            
        ]
    },

     {
        name: 'windmillBaseMap',
        type: 'textureMap',
        path: 
        [
            '../texturesBakedDay/Windmill_Base_forSeamFix_lambert31_BaseColor.jpg'
            
        ]
    },


    // {
    //     name: 'windmillBaseMap_Night',
    //     type: 'textureMap',
    //     path: 
    //     [
    //         '../texturesBakedNight/baked-Windmill_BaseShape_Night.jpg'
            
    //     ]
    // },



    {
        name: 'windmillTopMap',
        type: 'textureMap',
        path: 
        [
            '../texturesBakedDay/baked-Windmill_TopShape.jpg'
            
        ]
    },






    {
        name: 'cabinBaseMap',
        type: 'textureMap',
        path: 
        [
            '../texturesBakedDay/baked-Cabin_BaseShape.jpg'
            
        ]
    },

    {
        name: 'cabinBodyMap',
        type: 'textureMap',
        path: 
        [
            '../texturesBakedDay/baked-Cabin_BodyShape.jpg'
            
        ]
    },
    
    {
        name: 'cabinTilesMap',
        type: 'textureMap',
        path: 
        [
            '../Windmill_Island/Windmill_Island_forSubstance_cabin_Tiles_BaseColor.jpg'
            
        ]
    },


    {
        name: 'grassCOL',
        type: 'textureMap',
        path: 
        [
            '../Windmill_Island/Grass_COL.jpg'
            
        ]
    },


     {
        name: 'skyMap',
        type: 'textureMap',
        path: 
        [
            '../Windmill_Island/Sky_02.jpg'
            
        ]
    },

    {
        name: 'skyMapVarientNight',
        type: 'textureMap',
        path: 
        [
            '../Windmill_Island/Sky_01.jpg'
            
        ]
    },




]