import * as THREE from 'three'
import Scene from './Scene.js'
import Sizes from '../Utilities/Sizes.js'
import Time from '../Utilities/Time.js'
import Camera from './Camera.js'
import CameraMobile from './CameraMobile.js'
import CameraTablet from './CameraTablet.js'
import Renderer from './Renderer.js'
import Resources from '../Utilities/Resources.js'
import sources from './sources.js'
import sourcesLow from './sourcesLow.js'
import Slider from './Slider.js'
import Materials from './Materials.js'
import Lanterns from './Lanterns.js'
import Lights from './Lights.js'
import LightsTablet from './LightsTablet.js'
import LightsDesktop from './LightsDesktop.js'
import LoadingScreen from './LoadingScreen.js'
import LoadingAnimation from './LoadingAnimation.js'
import Animations from './Animations.js'
import VarientNight from './VarientNight.js'
// import Clouds from './Clouds.js'
import ScrollAnimationDesktop from './ScrollAnimationDesktop.js'
import ScrollAnimationMobile from './ScrollAnimationMobile.js'
import ScrollAnimationTablet from './ScrollAnimationTablet.js'
//import { element } from 'three/examples/jsm/nodes/shadernode/shadernode.js'



let instance = null

export default class Experience
{
    constructor(canvas)
    {
        if(instance)
        {
            return instance
        }
        
        instance = this

        //Global access
        window.experience = this


        this.canvas = canvas
        
        //Set up
        this.sizes = new Sizes()
        this.time = new Time()
        this.scene = new Scene()



        // Find device type by screen size
        var isMobile = window.matchMedia("(max-width: 767px)").matches;
        var isTablet = window.matchMedia("(min-width: 768px) and (max-width: 1023px)").matches;
        
        


        if (isMobile) {
          // The site is being accessed on a mobile device
            //console.log("Mobile device");
            this.resources = new Resources(sources)
            this.camera = new CameraMobile()
            this.animations = new Animations()
            this.materials = new Materials()
            //this.lanterns = new Lanterns()
            //this.lights = new Lights()
            this.renderer = new Renderer()
            this.loadingScreen = new LoadingScreen()
            this.loadingAnimation = this.loadingScreen.loadingAnimation
            this.scrollAnimation = new ScrollAnimationMobile()




        } else if (isTablet) {
            // The site is being accessed on a tablet device
            //console.log("Tablet device");
            this.resources = new Resources(sources)
            // this.camera = new CameraTablet()
            this.camera = new CameraTablet()
            this.animations = new Animations()
            this.materials = new Materials()
            //this.lanterns = new Lanterns()
            //this.lights = new LightsTablet()
            this.renderer = new Renderer()
            this.loadingScreen = new LoadingScreen()
            this.loadingAnimation = this.loadingScreen.loadingAnimation
            // this.scrollAnimation = new ScrollAnimationTablet()
            this.scrollAnimation = new ScrollAnimationDesktop()
            // this.lights.turnOffShadowsOnNightLights()
            




        // } else if (isTablet) {
        //     // The site is being accessed on a tablet device
        //     console.log("Tablet device");
        //     this.resources = new Resources(sources)
        //     // this.camera = new CameraTablet()
        //     this.camera = new Camera()
        //     this.animations = new Animations()
        //     this.materials = new Materials()
        //     this.lanterns = new Lanterns()
        //     this.lights = new LightsDesktop()
        //     this.renderer = new Renderer()
        //     this.loadingScreen = new LoadingScreen()
        //     this.loadingAnimation = this.loadingScreen.loadingAnimation
        //     // this.scrollAnimation = new ScrollAnimationTablet()
        //     this.scrollAnimation = new ScrollAnimationDesktop()
        //     this.lights.turnOffShadowsOnNightLights()
            

        } else {
            // The site is being accessed on a desktop or larger screen device
            //console.log("Desktop or larger screen device");
            this.resources = new Resources(sources)
            this.camera = new Camera()
            this.animations = new Animations()
            this.materials = new Materials()
            //this.lanterns = new Lanterns()
            //this.lights = new LightsDesktop()
            this.renderer = new Renderer()
            this.loadingScreen = new LoadingScreen()
            this.loadingAnimation = this.loadingScreen.loadingAnimation
            this.scrollAnimation = new ScrollAnimationDesktop()
  
        }

        




        



        //Sizes resize event
        this.sizes.on('resize', () =>
        {
            this.resize()
            
            function refreshPage() {
                location.reload();
              }
            
            var inMobileZone = false




            //Switch when window is scaled from mobile back passed cut off.
            if (this.sizes.width >= 730 && this.sizes.width <= 750){
                //this.camera = new CameraMobile()
                // this.scrollAnimation.turnOffScrollTrigger()
                // this.scrollAnimation.triggerAnimationsMobile()
                // var isMobile = true
                // console.log("isMobile", isMobile)
                refreshPage()
                }
            
            


            // //Switch when window is scaled from mobile back passed cut off.
            // if (isMobile && this.sizes.width >= 740 && this.sizes.width <= 767){
            //     console.log("underpants")
            //     //this.camera = new CameraMobile()
            //     refreshPage()

            //     }

            // if (isTablet && this.sizes.width >= 1000 && this.sizes.width <= 1020){
            //     console.log("underpants")
            //     //this.camera = new CameraMobile()
            //     refreshPage()
            //     }



            
        } )


        //Time tick event
        this.time.on('tick', () =>
        {
            this.update()
        })

    
        
    }
    

    resize()
    {

        this.camera.resize()
        this.renderer.resize()

    }

    
   


    update()
    {   
        
        this.camera.update()

        if (this.scrollAnimation.worldLayout){
            this.scrollAnimation.worldLayout.update()
        }

        this.renderer.update()
        

        
        //UPDATING LOADING BAR
        if (this.loadingScreen.resources.progressRatio<1) {
            console.log(this.loadingAnimation.resources.progressRatio)
            this.loadingScreen.update() 
        }
        
        // if (this.loadingScreen.resources.progressRatio<=0.95) {
        //     //console.log(this.loadingAnimation.resources.progressRatio)
        //     this.loadingScreen.loadingBarFadeOff()
        //     this.loadingScreen.section_01FadeOn()
            
        // }

        // if (this.loadingScreen.resources.progressRatio>0) {
        //     //console.log(this.loadingAnimation.resources.progressRatio)
            
            
        // }
        
    }

    
}